
import { ref, defineAsyncComponent } from 'vue'
import { api } from '@/api/useAxios'

export default {
  components: {
    ScWorkList: defineAsyncComponent(() => import('./components/ScWorkList.vue')),
    ScCourseList: defineAsyncComponent(() => import('./components/ScCourseList.vue')),
    ScDailyList: defineAsyncComponent(() => import('./components/ScDailyList.vue')),
    ScProductList: defineAsyncComponent(() => import('./components/ScProductList.vue'))
  },
  setup: () => {
    const courseList = ref<[]>([])
    const workList = ref<[]>([])
    const productList = ref<[]>([])
    const dailyList = ref<[]>([])

    api.get('/mmshoucang/qryMyShouCangCourseInfos').then(r => {
      courseList.value = r.courseList
      workList.value = r.workList
      productList.value = r.productList
      dailyList.value = r.dailyList
    })
    const activeName = ref('1');
    return {
      activeName,
      courseList,
      workList,
      productList,
      dailyList
    }
  }
}
